/* Контакты */
.contact {
    position: relative;
}

.contact__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
}

.contact__link {
    display: flex;
    align-items: center;
    color: #668faf;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.contact__icon {
    margin-right: 0.8rem;
}

.contact__title {
    font-size: 3rem;
    font-weight: 700;
    color: #13599f;
}

.contact__box {
    margin-top: 3rem;
}

.contact__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 9rem;
}

.contact__map {
    width: 635px;
    height: 470px;
}

.contact__map--map {
    width: 100%;
    height: 100%;
}

.contact__form {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact__input--inner,
.contact__input--inner:nth-child(2) {
    margin-top: 4rem;
}

.contact__button {
    width: 100%;
    margin-top: 5rem;
}

.contact__button:disabled {
    background: #bababa;
    box-shadow: none;
}

@media (max-width: 1200px) {
    .contact__form {
        max-width: 300px;
    }

    .contact__map {
        width: 550px;
        height: 400px;
    }
}
@media (max-width: 998px) {
    .contact__content {
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
    }

    .contact__form {
        max-width: 600px;
    }

    .contact__map {
        width: 100%;
        margin-top: 4rem;
    }

    .contact__box {
        padding: 10rem 4rem 4rem 4rem;
        margin-top: 12rem;
    }
}
@media (max-width: 768px) {
    .contact__title {
        font-size: 2.2rem;
    }

    .contact__wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact__link {
        margin-top: 1rem;
    }

    .contact__link:first-child {
        margin-top: 0;
    }
}
@media (max-width: 480px) {
    .contact__box {
        padding: 8rem 2rem 2rem 2rem;
        margin-top: 8rem;
    }

    .contact__input--inner,
    .contact__input--inner:nth-child(2),
    .contact__button {
        margin-top: 3rem;
    }

    .contact__map {
        height: 300px;
    }
}
