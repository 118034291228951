// body {
//   font-family: Inter, sans-serif;
//   margin: 0;
//   padding: 0;
//   overflow-x: hidden;
//   width: 100%;
//   height: 100vh;
// }
.dashBoard__nav {
  color: rgb(38, 86, 207);
  padding: 20px;
  // position: fixed;
}

.dashBoard__navLink {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  color: azure;
  margin-top: 15px;
  margin-left: 5px;
  display: block;
}

@mixin mywidth($count) {
  width: (1 / $count) * 100%;
}

.dashBoard_operation__item--date {
  width: 1/9 * 100%;
}
.dashBoard_content__margin {
  // margin: 2.5rem;
}
.dashBoard_w100 {
  width: 100%;
}
.dashBoard_flexcenter,
.dashBoard_flex,
.dashBoard_flexend {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}
.dashBoard_flexdef,
.dashBoard_flexdefend {
  display: flex;
  align-items: center;
}
// .dashBoard_admin__table--blue {
//   color: #174073;
// }
// .dashBoard_operation__item--num {
//   width: 1/9 *100%;
// }
.dashBoard_operation__item--column3 {
  width: (1)/3 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column5 {
  width: (1)/5 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column6min2 {
  width: 0.5/6 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column6 {
  width: (1+1/6)/6 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column7min2 {
  width: 0.5/7 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column7 {
  width: (1+1/7)/7 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column8min4 {
  width: 0.5/8 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column8min2 {
  width: 0.5/8 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column8 {
  width: (1+1/8)/8 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column8max {
  width: (3)/8 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column9min2 {
  width: 0.5/9 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column9 {
  width: (1+1/9)/9 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column11min2 {
  width: 0.5/11 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column11 {
  width: (1+1/11)/11 * 100%;
  // border: 1px solid #3786E5;
}
.dashBoard_operation__item--column12min2 {
  width: 0.5/12 * 100%;
}
.dashBoard_operation__item--column12 {
  width: (1+1/12)/12 * 100%;
}
.dashBoard_operation__item--column12m {
  width: (1)/12 * 100%;
}
.dashBoard_operation__item--column12max2 {
  width: (1.5)/12 * 100%;
}

// .dashBoard_operation__item--date {
//   width: 1/9 *100%;
// }

// .dashBoard_operation__item--desc {
//   width: 1/9 *100%;
// }

// .dashBoard_operation__item--in {
//   width: 1/9 *100%;
// }

// .dashBoard_operation__item--out {
//   width: 1/9 *100%;
// }

.dashBoard_admin__table {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.dashBoard_admin__table--item {
  padding: 1px;
  border-bottom: 1px solid #dfdfdf;
  // &:first-child{
  //   padding: 1.3rem 1.5rem;
  // }
}

.dashBoard_admin__table--item:first-child {
  border-bottom: 1px solid #3786e5;

  // padding: 1.3rem 1.5rem;
}

.dashBoard_admin__table--item:last-child {
  border-bottom: none;
  // padding-bottom: 2px;
  // padding: 5px ;
}
.myAbsoluteLeft {
  position: absolute;
  top: 0;
  right: 0;
}

// .dashBoard_admin__table--text {
//   font-size: 1.4rem;
//   font-weight: 500;
//   color: #222;
//   padding-right: 1rem;
// }

// .dashBoard_admin__table--blue {
//   color: #174073;
// }

// .dashBoard_admin__table--program {
//   width: 1/9 *100%;
// }

// .dashBoard_admin__table--date {
//   width: 1/9 *100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-wrap: wrap;
// }

// .dashBoard_admin__table--sum {
//   width: 1/9 *100%;
// }
@media (max-width: 768px) {
  .dashBoard_admin__table--program,
  .dashBoard_admin__table--sum {
    width: 35%;
  }

  .dashBoard_admin__table--date {
    width: 30%;
  }
}
@media (max-width: 500px) {
  .dashBoard_content__margin {
    margin-top: 1.5rem;
    justify-content: center;
    /* align-items: baseline; */
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .dashBoard_admin__table--item {
    flex-direction: column;
    align-items: center;
  }

  // .dashBoard_admin__table--text {
  //   width: 100%;
  //   text-align: center;
  //   padding: 0;
  //   margin-top: 0.8rem;
  //   flex-direction: column;
  //   align-items: center;
  // }

  .dashBoard_admin__table--program {
    margin-top: 0;
  }
  .dashBoard_operation__item--column12min2,
  .dashBoard_operation__item--column12max2,
  .dashBoard_operation__item--column12m,
  .dashBoard_operation__item--column12,
  .dashBoard_operation__item--column11min2,
  .dashBoard_operation__item--column11,
  .dashBoard_operation__item--column9min2,
  .dashBoard_operation__item--column9,
  .dashBoard_operation__item--column8min2,
  .dashBoard_operation__item--column8,
  .dashBoard_operation__item--column7min2,
  .dashBoard_operation__item--column7,
  .dashBoard_operation__item--column6min2,
  .dashBoard_operation__item--column6,
  .dashBoard_operation__item--column5min2,
  .dashBoard_operation__item--column5,
  .dashBoard_operation__item--column4min2,
  .dashBoard_operation__item--column4 {
    width: 100%;
  }
}

// https://codepen.io/FlorinCornea/pen/KKpvRYo

.circle-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
}

.icon {
  position: absolute;
  color: #fff;
  font-size: 55px;
  top: 55px;
  left: 55px;
  // transform: translate(-50%, -50%);
}

.circle {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  // animation: spin 10s linear infinite;
}

.circle-wrapper:active .circle {
  // animation: spin 2s linear infinite;
}

.success {
  background-color: #4bb543;
  border: 2.5px dashed #4bb543;
}

.error {
  background-color: #ca0b00;
  border: 2.5px dashed #ca0b00;
}

.warning {
  background-color: #f0d500;
  border: 2.5px dashed #f0d500;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.page-wrapper {
  height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
