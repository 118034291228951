@keyframes shake {
  0% {
    transform: translate(-49%, -49%) rotate(0deg);
  }

  10% {
    transform: translate(-51%, -52%) rotate(-1deg);
  }

  20% {
    transform: translate(-53%, -50%) rotate(1deg);
  }

  30% {
    transform: translate(-47%, -48%) rotate(0deg);
  }

  40% {
    transform: translate(-51%, -49%) rotate(1deg);
  }

  50% {
    transform: translate(-51%, -48%) rotate(-1deg);
  }

  60% {
    transform: translate(-53%, -49%) rotate(0deg);
  }

  70% {
    transform: translate(-47%, -51%) rotate(-1deg);
  }

  80% {
    transform: translate(-49%, -49%) rotate(1deg);
  }

  90% {
    transform: translate(-51%, -52%) rotate(0deg);
  }

  100% {
    transform: translate(-51%, -48%) rotate(-1deg);
  }
}

.modal__wrapper {
  background: rgba(102, 102, 102, 0.68);
  width     : 100%;
  height    : 100%;
  position  : fixed;
  top       : 0;
  left      : 0;
  z-index   : 50;
  overflow-y: hidden;
}

.modal__text {
  position        : absolute;
  top             : 50%;
  left            : 50%;
  width           : 400px;
  border          : 1px solid #cecece;
  padding         : 50px;
  transform       : translate(-50%, -50%);
  background-color: #fafafa;
}

.captcha_wrapper {
  border-radius: 5px;
  border       : none !important;
  width        : initial !important;
  box-shadow   : 9px 12px 8px 0px rgba(34, 60, 80, 0.2);
  padding      : 20px !important;

}

.captcha_fail {
  animation                : shake 0.5s;
  animation-iteration-count: infinite;
}


.vertifyWrap .slider {
  top: -20px;
}

.vertifyWrap .sliderContainer_active .slider {
  top: -20px;
}

.vertifyWrap .sliderContainer_success .slider {
  top: -20px;
}

.vertifyWrap .sliderContainer_fail .slider {
  top: -20px;
}