/* Платежные системы */
.payment{
    position: relative;
}

.payment__inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.payment__title{
    margin-top: 12rem;
}

.payment__save--inner{
    width: 100%;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    grid-gap: 30px;
}

.payment__save--item{
    padding: 3.5rem 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
}

.payment__save--title{
    margin-top: 2rem;
    font-weight: 700;
    color: #13599f;
    font-size: 2rem;
    text-transform: uppercase;
}

.payment__save--text{
    margin-top: 2rem;
    font-size: 1.4rem;
    color: #24333f;
}

.payment__content{
    width: 100%;
    margin-top: 4rem;
    display: flex;
    align-items: flex-end;
}

.payment__procent{
    margin-right: 5rem;
    flex-shrink: 0;
    font-size: 20rem;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    color: #0A7CEE;
    text-shadow: 0 0 20px #0A7CEE;
}

.payment__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.payment__button--inner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment__button{
    width: 48%;
    padding: 1.4rem 3rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    background: #838383;
    border-radius: 5px;
    box-shadow: 0px 0px 0px #158AFF, inset 0px -4px 0px rgba(0, 0, 0, 0.15);
}

.payment__button:hover{
    background: #0A7CEE;
    box-shadow: 0px 4px 20px #158AFF, inset 0px -4px 0px rgba(0, 0, 0, 0.15);
}

.payment__variant--inner{
    width: 100%;
    margin-top: 5rem;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}

.payment__item{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    transition: .2s linear;
    cursor: pointer;
    background: #fff;
}

.payment__item:hover{
    box-shadow: 0px 0px 10px #158AFF;
    border: 2px solid #158AFF;
}

.payment__item--img{
    max-width: 80%;
}

.payment__text{
    margin-top: 2rem;
}

@media(max-width: 768px){
    .payment__content{
        flex-direction: column;
        align-items: center;
    }

    .payment__procent{
        margin-right: 0;
        font-size: 15rem;
    }

    .payment__button{
        font-size: 1.8rem;
    }

    .payment__variant--inner{
        margin-top: 3rem;
        grid-gap: 20px;
    }

    .payment__title{
        margin-top: 5rem;
    }

    .payment__save--inner{
        margin-top: 2rem;
    }

    .payment__wrapper{
        margin-top: 2rem;
    }
}
@media(max-width: 480px){
    .payment__save--inner{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .payment__save--item{
        padding: 1.5rem;
    }

    .payment__procent{
        font-size: 10rem;
    }
}