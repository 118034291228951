:root {
    font-size: 10px;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 1.6rem;
    font-family: "Raleway", sans-serif;
}

@font-face {
    font-family: "Graphik";
    src: url("assets/fonts/GraphikLCGRegular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("assets/fonts/GraphikLCGMedium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("assets/fonts/GraphikLCGBold.ttf");
    font-weight: 700;
    font-style: normal;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

a,
button,
input {
    transition: 0.2s linear;
    font-family: "Raleway", sans-serif;
    font-size: 1.6rem;
}

.rsc-cursor {
    /* color: rgba(255, 255, 255, 0.8); */
    fill: red;
    stroke: rgb(238, 8, 8);
    stroke-width: 10px;
    stroke-dasharray: 2, 2;
    stroke-linejoin: round;
}

a {
    text-decoration: none;
    color: #000;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
    margin: 0;
    font-weight: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
}

.button {
    border: none;
    cursor: pointer;
    background: none;
    outline: none;
}

.admin {
    font-family: "Graphik", sans-serif;
}

.input {
    outline: none;
    border: none;
    background: none;
}

.input::placeholder {
    color: #acbdce;
}

.input:focus::placeholder {
    opacity: 0;
}

.select {
    outline: none;
    cursor: pointer;
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
}

.mt1 {
    margin-top: 1rem;
}

.mt2 {
    margin-top: 2rem;
}

.scroll {
    overflow: hidden;
}

.black {
    font-weight: 900;
}

.blue {
    color: #13599f;
}

.white {
    color: #fff;
}

.title {
    font-weight: 700;
    color: #acbdce;
    font-size: 4rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.title__center {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.section__padding {
    padding: 6rem 0;
}

.text__status {
    color: #439c43;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.text__date {
    display: flex;
    align-items: center;
    color: #4e80a8;
}

.text__date.white {
    color: #fff;
}

.text__date--img {
    margin-right: 1rem;
}

.medium__text {
    font-size: 2rem;
    color: #24333f;
}

.default__button {
    padding: 1.9rem 7rem;
    font-size: 2rem;
    color: #fff;
    font-weight: 500;
    background: #0a7cee;
    box-shadow: 0px 4px 20px #158aff, inset 0px -4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
}

.default__button:hover {
    background: #0e63b7;
    box-shadow: 0px 4px 20px #0e63b7, inset 0px -4px 0px rgba(0, 0, 0, 0.15);
}

.text__name {
    font-size: 2.5rem;
    font-weight: 700;
    color: #13599f;
}

.dots {
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 50px;
    right: 50px;
}

.img {
    width: 100%;
    height: auto;
}

.image_draw {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.img__auto {
    max-width: 100%;
    max-height: 100%;
}

.flexs {
    flex-shrink: 0;
}

.w100 {
    width: 100%;
}

.w98 {
    width: 98%;
}

.flexcenter,
.flex,
.flexend {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
}

.flexdef,
.flexdefend {
    display: flex;
    align-items: center;
}

.flexdefend {
    align-items: flex-end;
}

.flexend {
    align-items: flex-end;
}

.flex {
    align-items: flex-start;
}

.flexbet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.default__margin {
    margin-top: 4rem;
}

.icon__def {
    margin-right: 0.5rem;
}

.mt1 {
    margin-top: 1rem;
}

.mr1 {
    margin-right: 1rem;
}

.ml1 {
    margin-left: 1rem;
}

.mb1 {
    margin-bottom: 1rem;
}

.mall {
    margin: 1rem;
}

.flexw {
    flex-wrap: wrap;
}

/* Авторизация */
.auth {
    position: relative;
}

.auth__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth__content {
    width: 100%;
    max-width: 730px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5rem;
}

.auth__links {
    width: 100%;
    max-width: 730px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
}

.auth__link {
    margin-top: 1.5rem;
}

.auth__link:first-child {
    margin-top: 0;
}

.auth__input--inner {
    width: 47%;
    margin-top: 3.5rem;
    height: 45px;
}

.auth__input--inner.full {
    width: 100%;
    height: 60px;
}

.auth__input--inner:first-child,
.auth__input--inner:nth-child(2) {
    margin-top: 0;
}

.auth__input {
    width: 100%;
    font-size: 2rem;
    color: #668faf;
    padding-bottom: 0.7rem;
    border-bottom: 2px solid #acbdce;
}

.auth__input:focus {
    border-bottom: 2px solid #007cee;
}

.auth__checkbox {
    display: none;
}

.auth__label {
    position: relative;
    cursor: pointer;
    padding-left: 3.5rem;
    font-size: 2rem;
    color: #668faf;
    display: block;
}

.auth__label:before,
.auth__label:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
}

.auth__label:before {
    width: 24px;
    height: 24px;
    box-shadow: 2px 2px 5px #d7d7d7, -2px -2px 5px #ffffff, inset 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    top: 0;
}

.auth__label:after {
    width: 16px;
    height: 14px;
    background: url("assets/img/check.svg") center no-repeat;
    background-size: cover;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0;
    transition: 0.2s linear;
}

.auth__checkbox:checked + .auth__label:after {
    opacity: 1;
}

.auth__button {
    margin-top: 6rem;
}

/* Новости */
.news__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news__content {
    width: 100%;
    margin-top: 6rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
}

.news__item {
    display: flex;
}

.news__item--img {
    width: 500px;
    height: 300px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
}

.news__item--text--inner {
    width: 100%;
    padding: 2.5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0 10px 10px 0;
    background: #fff;
}

.news__text {
    margin-top: 1.5rem;
}

.news__wrapper {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: end; */
}

.news__date {
    display: flex;
    align-items: center;
}

.news__social--inner {
    display: flex;
    align-items: center;
}

.news__social--link {
    margin-right: 1.5rem;
    display: flex;
}

.news__social--link:last-child {
    margin-right: 0;
}

.news__social--img {
    width: 100%;
    height: auto;
}

.news__button {
    margin-top: 5rem;
}

.news__box,
.social__box,
.rep__box,
.contact__box {
    margin-top: 4rem;
    width: 100%;
    position: relative;
    padding: 3rem;
    background: url("assets/img/news-fon.jpg") center no-repeat;
    background-size: cover;
}

.rep__box,
.contact__box {
    margin-top: 0;
    background: none;
}

.social__box {
    background: url("assets/img/smi-fon.jpg") center no-repeat;
    background-size: cover;
}

.news__contained {
    display: flex;
    justify-content: flex-end;
}

.news__box--text--inner,
.report__box--text--inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 605px;
}

.report__box--text--inner {
    max-width: 700px;
}

.news__box--text--title {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}

.news__box--text--text {
    color: #fff;
    margin-top: 2rem;
}

.news__box--img,
.smi__box--img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    max-height: 100%;
    width: 36vw;
    pointer-events: none;
}

.smi__box--img {
    left: auto;
    right: 0;
}

/* Отчеты */
.report__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.report__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
}

.report__item {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.report__item:first-child {
    margin-top: 0;
}

.report__date {
    color: #13599f;
    font-size: 3rem;
    font-weight: 700;
}

.report__box {
    width: 100%;
    margin-top: 2.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2rem;
}

.report__box--item {
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.report__box--date {
    width: 20%;
}

.report__box--type {
    width: 10%;
}

.report__box--operate {
    width: 20%;
}

.report__box--price {
    width: 20%;
}

.report__box--val {
    width: 20%;
}

.report__box--total {
    width: 10%;
}

.report__box--item p {
    font-size: 1.4rem;
    color: #24333f;
}

.report__box--item p.grey,
.grey {
    color: #90a1ae;
}

.report__analitic {
    width: 100%;
    margin-top: 4rem;
}

.rep__box--text--inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
}

.rep__box--text {
    color: #24333f;
    margin-top: 2rem;
}

.rep__box--text:first-child {
    margin-top: 0;
}

/* С чего начать */
.start {
    position: relative;
}

.start__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.start__content {
    width: 100%;
    margin-top: 5rem;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.start__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.start__item:before {
    content: "";
    display: block;
    background: #acbdce;
    width: calc(100% + 30px);
    height: 2px;
    position: absolute;
    z-index: -1;
    top: 121px;
    left: 50%;
    transform: translateX(-50%);
}

.start__item:first-child:before,
.start__item:last-child:before {
    right: -15px;
    left: auto;
    width: calc(50% + 15px);
    transform: translateX(0%);
}

.start__item:last-child:before {
    right: auto;
    left: -15px;
}

.start__item--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.start__item--icon--inner {
    display: flex;
    width: 76px;
    height: 76px;
    align-items: center;
    justify-content: center;
}

.start__item--icon {
    width: auto;
    height: auto;
}

.start__item--circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    box-shadow: 0px 4px 20px #158aff, inset 0px -2px 0px rgba(0, 0, 0, 0.15);
    border: 3px solid #006fde;
    margin-top: 3rem;
}

.start__item--circle:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: #006fde;
    box-shadow: 0px 4px 20px #158aff, inset 0px -2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 4px;
}

.start__item--title {
    font-size: 2rem;
    font-weight: 900;
    color: #13599f;
    text-align: center;
    margin-top: 2rem;
}

.start__item--text {
    margin-top: 1.5rem;
    text-align: center;
    color: #24333f;
}

.start__item--button {
    width: 100%;
    padding: 1.4rem 2rem;
    font-size: 1.6rem;
    margin-top: 2rem;
}

.privat__button {
    background: none;
    border: 1px solid #158aff;
    color: #90a1ae;
    box-shadow: none;
}

.privat__button:hover {
    color: #fff;
}

.time {
    background: url("assets/img/mission-fon.jpg") center no-repeat;
    background-size: cover;
    padding: 2rem 0;
}

.time__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 9rem;
}

.time__wrapper:last-child {
    margin-right: 0;
    align-items: flex-start;
}

.time__title,
.time__text {
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
    max-width: 275px;
}

.time__text {
    margin-top: 2rem;
    font-size: 1.6rem;
    font-weight: 400;
}

.time__img--inner {
    margin-top: 2rem;
    border: 1px solid #fff;
    border-radius: 20px;
    display: flex;
    margin-left: 15px;
}

.time__img,
.time__img--preview {
    max-width: 100%;
}

.time__img--preview {
    position: relative;
    top: 15px;
    left: -15px;
}

.program {
    padding: 5rem 0 10rem 0;
    background: #ecedef;
    position: relative;
}

.program__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.program__content {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.program__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
}

.program__item--img--inner {
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.program__item--img {
    max-width: 100%;
    max-height: 100%;
}

.program__item--text {
    text-align: center;
    color: #24333f;
    margin-top: 3rem;
}

.circle__img {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.vector {
    padding: 0rem 0rem 7rem 0;
    position: relative;
}

.vector__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vector__content {
    width: 100%;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    position: relative;
    top: -50px;
}

.vector__item {
    border-radius: 10px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    transition: 0.2s linear;
    background: #fff;
}

.vector__item:hover {
    background: #207cd8;
}

.vector__item--icon path {
    transition: 0.2s linear;
}

.vector__item:hover .vector__item--icon path {
    fill: #fff;
}

.vector__item:hover .vector__item--title,
.vector__item:hover .vector__item--text {
    color: #fff;
}

.vector__item--title {
    color: #13599f;
    font-weight: 700;
    margin-top: 2.5rem;
    transition: 0.2s linear;
}

.vector__item--text {
    transition: 0.2s linear;
    font-size: 1.4rem;
    color: #24333f;
    margin-top: 1.7rem;
}

.vector__box {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.vector__box--item {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
}

.vector__box--img--inner {
    width: 76px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector__box--img {
    max-width: 100%;
    max-height: 100%;
}

.vector__box--text {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #13599f;
    margin-top: 3rem;
}

.vector__wave {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 400px;
    max-width: 100%;
}

.review__title {
    margin-top: 8rem;
}

.review__content {
    width: 100%;
    margin-top: 4rem;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}

.review__item {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2rem 2.5rem;
    background: #fff;
    display: flex;
    align-items: flex-start;
}

.review__item--img {
    margin-right: 2rem;
    flex-shrink: 0;
    width: 110px;
    height: auto;
}

.review__item--name {
    font-weight: 900;
    font-size: 2rem;
    color: #158aff;
}

.review__item--text {
    margin-top: 1rem;
    color: #24333f;
    text-align: justify;
}

.review__button {
    margin-top: 3rem;
}

/* Мы строим */
.build__content {
    top: 0;
    margin-top: 5rem;
}

.some {
    padding: 8rem 0;
    position: relative;
}

.some__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.some__content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.certificate {
    padding: 4rem 0;
    background: url("assets/img/news-fon.jpg") center no-repeat;
    background-size: cover;
}

.certificate__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.certificate__text--inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 510px;
}

.certificate__text--title {
    color: #fff;
    font-weight: 700;
    font-size: 3rem;
}

.certificate__text--text {
    color: #fff;
    margin-top: 3rem;
}

.certificate__img {
    max-width: 100%;
}

/* Маркетинг */
.mark {
    padding: 5rem 0;
    background: url("assets/img/marketing-fon.png") center no-repeat;
    background-size: cover;
    margin-top: 4rem;
}

.mark__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mark__tab--inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mark__tab--inner.grey {
    margin-top: 7rem;
}

.mark__tab--inner.grey .mark__tab {
    color: #668faf;
}

.mark__tab--inner.grey .mark__tab.active {
    border: 1px solid #668faf;
}

.mark__tab {
    color: #fff;
    padding: 1rem 3rem;
    border-radius: 50px;
    margin-right: 4rem;
    font-size: 3rem;
    font-weight: 700;
}

.mark__tab:last-child {
    margin-right: 0;
}

.mark__tab.active {
    border: 1px solid #fff;
}

.mark__info--inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
}

.mark__info {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mark__info--img {
    width: 300px;
    height: auto;
    color: #fff;
}

.mark__info--title {
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
}

.mark__info--text {
    color: #fff;
    margin-top: 2rem;
}

.mark__point--inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2.5rem;
}

.mark__point {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    margin-top: 2rem;
}

.mark__point:first-child {
    margin-top: 0;
}

.mark__point--check {
    margin-right: 1rem;
}

.mark__content {
    width: 100%;
    margin-top: 5rem;
    display: grid;
    grid-gap: 60px 30px;
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
}

.mark__item {
    position: relative;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.mark__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.mark__item--desc {
    width: 100%;
    padding: 1.8rem 2.5rem 5rem 2.5rem;
    border-radius: 10px;
    background: #cbddec;
    text-align: center;
    color: #24333f;
    font-size: 1.5rem;
}

.mark__item--button {
    position: absolute;
    top: 100%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    padding: 1.6rem 4rem;
}

.mark__item--wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 2rem 3rem;
}

.mark__wrapper {
    display: flex;
    flex-direction: column;
}

.mark__item--wrapper--text {
    font-weight: 500;
    color: #24333f;
    max-width: 175px;
}

.mark__wrapper--title {
    font-size: 2.4rem;
    color: #668faf;
    font-weight: 500;
}

.mark__wrapper--text {
    font-size: 3rem;
    color: #13599f;
    font-weight: 700;
}

.mark__item--entry {
    padding: 0.7rem 1.5rem 0.7rem 2.8rem;
    background: linear-gradient(180deg, #13599f 0%, #006fde 100%);
    border-radius: 0px 20px 20px 0px;
    color: #fff;
    font-weight: 700;
}

.mark__item--content {
    width: 100%;
    padding: 0rem 2.5rem 2rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mark__item--val {
    font-size: 2rem;
    color: #13599f;
    font-weight: 700;
}

.mark__item--val.big {
    font-size: 3rem;
}

.line {
    position: relative;
    width: 100%;
    text-align: center;
    background: #fff;
}

.line:before,
.line:after {
    content: "";
    display: block;
    height: 1px;
    width: 27%;
    background: #a9c9dd;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 68px;
}

.line:after {
    right: 68px;
    left: auto;
}

.mark__item--box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4rem;
}

.mark__item--box--item {
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.mark__item--box--item_two {
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.mark__item--box--item:before {
    content: "";
    display: block;
    width: 2px;
    height: 50px;
    background: #a9c9dd;
    position: absolute;
    top: -58px;
}
.mark__item--box--item_two:before {
    content: "";
    display: block;
    width: 2px;
    height: 50px;
    background: #a9c9dd;
    position: absolute;
    top: -58px;
}


.mark__item--box--item:nth-child(2):before {
    top: -35px;
    height: 25px;
}

.mark__item--box--text--inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2.5rem;
}

.mark__item--box--text {
    font-size: 1.4rem;
    color: #668faf;
    margin-top: 1.5rem;
}

.mark__item--box--text:first-child {
    margin-top: 0;
}

/* Главная */
.main {
    padding: 10rem 0;
    position: relative;
}

.mains__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main__circle2,
.main__dots2 {
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 0;
    right: 0;
    max-height: 100%;
}

.main__dots2 {
    right: auto;
    top: 20px;
    left: 20px;
}

.main__text--inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 485px;
}

.main__video--inner {
    width: 520px;
    display: flex;
    border-radius: 20px;
    /* border: 3px solid #dfdfdf; */
}

.main__video--img {
    width: 100%;
    height: auto;
    position: relative;
    top: 30px;
    right: 30px;
}

.mains__button {
    margin-top: 5rem;
}

.mains__text {
    margin-top: 2rem;
    color: #24333f;
}

.company {
    padding: 6rem 0rem 18rem 0rem;
    background: url("assets/img/main-about-img.jpg") center no-repeat;
    background-size: cover;
}

.company__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company__title {
    text-align: center;
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}

.company__text {
    color: #fff;
    text-align: center;
    margin-top: 2rem;
}

.company__margin {
    margin-top: 5rem;
}

.company__content {
    top: -120px;
}

.company__button {
    margin-top: 1rem;
    position: relative;
    bottom: -28px;
}

.stats__inner {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.stats__item {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stats__item--val {
    text-align: center;
    font-size: 5rem;
    color: #158aff;
}

.stats__item--text {
    text-align: center;
    color: #24333f;
    font-size: 2rem;
    text-transform: lowercase;
}

.company__bg {
    background: linear-gradient(89.89deg, rgba(236, 237, 239, 0) 0%, #ecedef 49.92%, rgba(236, 237, 239, 0) 99.81%);
    padding: 0rem;
}

.arg {
    padding: 5rem 0;
    position: relative;
    background: url("assets/img/arg-img.png") center no-repeat;
    background-size: cover;
}

.arg__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-height: 100%;
    max-width: calc(43vw + 80px);
}

.arg__inner {
    width: 100%;
    max-width: 420px;
}

.arg__item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5rem;
}

.arg__item:first-child {
    margin-top: 0;
}

.arg__item--icon {
    margin-right: 3rem;
    flex-shrink: 0;
}

.arg__item--icon:hover {
    color: "#13599f";
}

.arg__item--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.arg__item--title {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 700;
}

.arg__item--text {
    color: #fff;
    margin-top: 1.5rem;
}

.mnews {
    padding: 9rem 0rem 5rem 0;
    position: relative;
}

.mnews__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mnews__content {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
}

.mnews__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mnews__item--text {
    width: 100%;
    padding: 7rem 2rem 1rem 2rem;
    /* border-radius: 0 0 8px 8px; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 57.29%);
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
    position: absolute;
    bottom: 0;
    /* left: 0%; */
    max-width: 500px;
}

.mnews__item--title {
    font-weight: 700;
    color: #13599f;
}

.mnews__item--subtitle {
    margin-top: 0.5rem;
    color: #24333f;
    font-size: 1.4rem;
}

.mnews__item--date {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: #4e80a8;
    font-size: 1.4rem;
}

.mnews__item--date--icon {
    margin-right: 0.7rem;
}

.mnews__item--img {
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.last__inner {
    width: 100%;
    margin-top: 7rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.last__content,
.last__review {
    width: 100%;
    max-width: 615px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.last__review {
    max-width: 445px;
}

.last__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3rem;
    font-weight: 700;
    color: #13599f;
}

.last__wrapper {
    width: 100%;
    margin-top: 4rem;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.last__review--item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.last__review--item:first-child {
    margin-top: 0;
}

.last__review--img {
    flex-shrink: 0;
    margin-right: 2rem;
}

.last__wrapper--item {
    width: 100%;
    padding: 1.4rem 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.last__wrapper--item:first-child {
    padding: 0rem 0rem 1.4rem 0rem;
}

.last__wrapper--item:last-child {
    border-bottom: none;
    padding: 1.4rem 0rem 0rem 0;
}

.last__wrapper--date,
.last__wrapper--login {
    width: 40%;
    font-family: "Montserrat", sans-serif;
    text-align: center;
}

.last__wrapper--total {
    width: 20%;
    font-family: "Montserrat", sans-serif;
    text-align: center;
}

.admin__body {
    background: #1c3b6a;
    min-height: 100vh;
    width: 100%;
    font-family: "Graphik", sans-serif;
}

.admin__header {
    padding: 1.5rem 3rem;
    position: sticky;
    top: 0px;
    background: #1c3b6a;
    width: 100%;
    z-index: 5;
}

.admin__header--inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin__header--wrapper {
    display: flex;
    align-items: center;
}

.admin__header--logo--inner {
    width: 100px;
    display: flex;
    margin-right: 22rem;
}

.admin__balance {
    color: #fff;
    font-weight: 500;
}

.green__color {
    color: #61d64a;
    font-weight: 400;
}

.admin__header--data--wrapper,
.admin__header--settings {
    display: flex;
    align-items: center;
}

.admin__header--settings {
    margin-right: 9rem;
}

.admin__name {
    color: #fff;
    font-weight: 500;
    margin-right: 1rem;
}

.admin__img {
    width: 45px;
    margin-right: 1rem;
    display: flex;
}

.admin__control {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s linear;
    cursor: pointer;
}

.admin__dot_message {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #61d64a;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: blink 3s linear infinite;
}

.admin__dot_tickets {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: #319795;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: blink 3s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
        /* Непрозрачный текст */
    }

    50% {
        opacity: 1;
        /* Прозрачный текст */
    }

    100% {
        opacity: 0.5;
    }
}

.admin__control:hover {
    opacity: 0.6;
}

.admin__logout {
    background: #5b87c7;
}

.control {
    margin-left: 1rem;
    background: #344f7a;
}

.admin__logout--icon {
    max-width: 60%;
    max-height: 60%;
}

.admin__lang--inner {
    border-radius: 50px;
    padding: 0.7rem 1rem;
    border: 2px solid #9ca2b8;
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.admin__lang--icon {
    margin-right: 0.7rem;
}

.admin__lang--arrow {
    margin-left: 0.7rem;
}

.admin__container {
    width: 100%;
    display: flex;
    overflow-x: hidden;
}

/* Сайдбар */
.admin__sidebar {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 2.5rem;
    flex-shrink: 0;
}

.admin__content {
    width: 100%;
    background: #fff;
    border-radius: 10px 0 0 0;
    min-height: calc(100vh - 84px);
    padding: 3rem;
    display: flex;
    align-items: flex-start;
}

.admin__nav--item,
.admin__nav--wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.admin__nav--item {
    margin-top: 2.5rem;
}

.admin__nav--item:first-child {
    margin-top: 0;
}

.admin__nav--wrapper {
    margin-top: 0.2rem;
    flex-direction: column;
}

.admin__nav--icon {
    margin-right: 2rem;
    flex-shrink: 0;
}

.admin__nav--link {
    font-size: 1.6rem;
    color: #acafbe;
    margin-top: 0.7rem;
}

.admin__nav--link:hover {
    color: #fff;
}

.admin__nav--link:focus {
    color: #fff;
}

.admin__nav--link:first-child {
    margin-top: 0;
}

.admin__nav--link.big {
    font-size: 1.8rem;
    color: #fff;
}

.copy {
    width: 100%;
    display: inline-block;
    margin-top: 5rem;
    text-align: center;
    color: #fff;
    font-size: 1.4rem;
}

.admin__content--right {
    max-width: 290px;
    margin-left: 2rem;
    flex-shrink: 0;
}

.admin__title--inner {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #222;
    font-size: 2.2rem;
}

.admin__title--icon {
    margin-right: 1rem;
}

.content__margin {
    margin-top: 2.5rem;
}

.profile__content {
    background: linear-gradient(105.34deg, #161616 0%, #353535 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 3rem;
}

.profile__vip {
    color: #000000;
}

.profile__content--item {
    justify-content: space-between;
    padding-bottom: 2rem;
    /* border-bottom: 1px solid #4d4d4d; */
    margin-top: 2rem;
}

.profile__content--item:first-child {
    margin-top: 0;
}

.profile__content--item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.profile__content--info--img {
    width: 100px;
    height: auto;
    margin-right: 2rem;
    flex-shrink: 0;
}

.profile__content--info--img--change {
    color: #61d64a;
    cursor: pointer;
}

.profile__content--info--img--change:hover {
    text-decoration: underline;
}

.profile__content--info--name {
    color: #fff;
    margin-top: 1.2rem;
    font-size: 2rem;
    font-weight: 500;
}

.profile__content--info--mail {
    margin-top: 1.2rem;
    color: #a3abb7;
}

.profile__info--text {
    font-weight: 500;
    color: #fff;
    margin-right: 1rem;
}

.profile__ref--text {
    flex-shrink: 0;
}

.profile__info--value {
    color: #61d64a;
    font-size: 5rem;
    font-weight: 700;
}

.profile__text--wrapper {
    margin-left: 2rem;
}

.profile__ref--input {
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 1.5rem 1.6rem;
    font-size: 1.8rem;
}

.profile__ref--input::placeholder {
    color: #a1a1a1;
}

.profile__ref--button {
    margin-left: 1.5rem;
    flex-shrink: 0;
    background: #5e89cc;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1.8rem 6rem;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
}

.admin__news--item--img {
    width: 350px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
}

.admin__news--item,
.presents__item {
    margin-top: 2.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.presents__item {
    margin: 0;
}

.admin__news--item:first-child {
    margin-top: 0;
}

.admin__news--text--inner {
    background: #fff;
    padding: 1.3rem;
    border-radius: 0 0 10px 10px;
}

.admin__news--text--title {
    font-weight: 500;
    color: #344f7a;
    font-size: 1.8rem;
}

.admin__news--text--text {
    color: #a7a7a7;
    margin-top: 1rem;
}

.admin__chat {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1.3rem;
}

.admin__chat--item {
    margin-top: 1.5rem;
    align-items: stretch;
}

.admin__chat--item:first-child {
    margin-top: 0;
}

.admin__chat--item--img--inner {
    width: 50px;
    display: flex;
    position: relative;
    margin-right: 1rem;
    flex-shrink: 0;
}

.admin__chat--item--img--inner:before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #61d64a;
    position: absolute;
    bottom: 0;
    right: 5px;
}

.admin__chat--item--text {
    padding: 0.9rem 1rem;
    border-radius: 10px 10px 10px 0;
    background: #dde3ec;
    color: #344f7a;
    font-size: 1.4rem;
    font-weight: 500;
}

.admin__chat--send {
    margin-top: 3rem;
}

.admin__chat--input {
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 1.3rem 1.5rem;
}

.admin__send--button {
    cursor: pointer;
    margin-left: 1rem;
    flex-shrink: 0;
}

.admin__wallet {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.admin__wallet--item {
    min-height: 200px;
    border-radius: 10px;
    position: relative;
    justify-content: space-between;
    padding: 1.3rem;
}

.admin__wallet--item.orange {
    background: linear-gradient(105.34deg, #fd6770 0%, #fe944f 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.admin__wallet--item.blue {
    background: linear-gradient(105.34deg, #5433ff 0%, #3a6cfe 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.admin__wallet--item.green {
    background: linear-gradient(105.34deg, #1a8f18 0%, #3cdc4c 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.admin__wallet--bg {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    max-width: 100%;
    max-height: 100%;
}

.admin__wallet--text--inner {
    justify-content: space-between;
}

.admin__wallet--text {
    color: #fff;
    font-weight: 500;
}

.admin__wallet--coin--inner {
    width: 50px;
    height: 50px;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin__last {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.admin__last--item {
    padding: 1.3rem 1.5rem;
    border-bottom: 1px solid #dfdfdf;
}

.admin__last--item:last-child {
    border-bottom: none;
}

.admin__last--text {
    font-size: 1.4rem;
    font-weight: 500;
    color: #222;
    padding-right: 1rem;
}

.admin__last--blue {
    color: #174073;
}

.admin__last--program {
    width: 40%;
}

.admin__last--date {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.admin__last--sum {
    width: 25%;
}

/* Презентация */
.presents__content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    align-items: stretch;
}

.presents__button {
    margin-top: 2rem;
    padding: 1rem 3rem;
    background: #5e89cc;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
}

.anews__img--inner {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.anews__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Розыгрыши */
.draw__content {
    margin-top: 4rem;
    max-width: 920px;
}

.draw__title {
    color: #222;
    font-size: 2.4rem;
    margin-top: 4rem;
}

.draw__text {
    color: #5b5b5b;
    margin-top: 3rem;
}

.draw__button {
    padding: 1.7rem 7rem;
    color: #fff;
    background: #61d64a;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 4rem;
}

/* Пополнить счет */
.pay__content {
    background: linear-gradient(105.34deg, #161616 0%, #353535 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1rem 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pay__content .flex {
    margin: 1rem;
}

.pay__text {
    color: #fff;
    font-weight: 500;
}

.pay__title {
    color: #ffcb00;
    font-size: 3rem;
    font-weight: 500;
}

.pay__input {
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 1.5rem 1.8rem;
    margin-right: 1rem;
}

.pay__button,
.output__cansel {
    background: #61d64a;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
    padding: 1.5rem 5rem;
}

.pay__button:disabled {
    background: grey;
}

.output__cansel {
    padding: 0.6rem 1.3rem;
    font-size: 1.6rem;
}

.pay__history {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.pay__history--item {
    padding: 1.3rem 2rem;
    border-bottom: 1px solid #dfdfdf;
}

.pay__history--item:last-child {
    border-bottom: none;
}

.pay__item--name {
    width: 50%;
    font-weight: 500;
}

.pay__item--date,
.pay__item--sum {
    width: 25%;
    padding-left: 1rem;
    font-weight: 500;
}

.pay__wrapper {
    flex-wrap: wrap;
}

/* Операции */
.operation__text {
    color: #464648;
    font-size: 1.8rem;
    font-weight: 500;
}

.operation__select {
    margin-top: 1.5rem;
    width: 400px;
}

.operation__select {
    padding: 1.5rem 1.5rem;
    color: #a1a1a1;
    font-size: 1.8rem;
}

.operation__date {
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 1.1rem 1.5rem;
    font-size: 1.8rem;
    color: #a1a1a1;
    margin-top: 1.5rem;
    outline: none;
}

.operation__filter {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 2rem;
}

.operation__item--num {
    width: 12%;
}

.operation__item--date {
    width: 28%;
}

.operation__item--desc {
    width: 28%;
}

.operation__item--in {
    width: 16%;
}

.operation__item--out {
    width: 16%;
    display: flex;
}

.operation__item--num1 {
    width: 6%;
}

.operation__item--date1 {
    width: 20%;
}

.operation__item--article1 {
    width: 20%;
}

.operation__item--desc1 {
    width: 30%;
}

.operation__item--in1 {
    width: 10%;
}

.operation__item--out1 {
    width: 10%;
    display: flex;
}

.operation__input {
    margin-left: 2rem;
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    padding: 1rem 1.5rem;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 140px;
}

/* Тикеты */
.ticket__content {
    display: flex;
}

.ticket__dialogs {
    max-width: 420px;
    background: #1c3b6a;
    border-radius: 10px;
    padding: 1rem 0;
    margin-right: 2rem;
}

.ticket__wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 80vh;
    justify-content: space-between;
    padding: 3rem;
}

.ticket__send--content {
    margin-top: 2rem;
}

.ticket__input {
    resize: none;
    background: #ededed;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 1.3rem;
    font-size: 1.8rem;
}

.ticket__send--button {
    margin-top: 2rem;
}

.ticket__message--inner {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
}

.ticket__message--inner:first-child {
    margin-top: 0;
}

.ticket__message--inner.me {
    /* justify-content: flex-end; */
}

.ticket__message--inner.opponent .ticket__message {
    border-radius: 15px 15px 15px 0;
    background: #cadfff;
}

.ticket__message {
    font-size: 1.4rem;
    color: #344f7a;
    background: #dde3ec;
    border-radius: 15px 15px 0px 15px;
    padding: 1rem 1rem;
    margin-bottom: 12px;
    max-width: 65%;
}

.ticket__ava--inner {
    position: relative;
    margin-right: 0 rem;
    min-width: 95px;
}

/* .ticket__ava--inner:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #61d64a;
    position: absolute;
    bottom: 2px;
    right: 7px;
} */
.point_online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #61d64a;
    position: absolute;
    bottom: 7px;
    right: 1px;
}

.point_online_chat_green {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #61d64a;
    position: absolute;
    bottom: 25px;
    left: 30px;
    animation: blink 3s linear infinite;
}

.point_online_chat_red {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: red;
    position: absolute;
    bottom: 25px;
    left: 30px;
}

.point_admin_chat_green {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #61d64a;
    animation: blink 3s linear infinite;
}

.point_admin_chat_red {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: red;
}

.ticket__ava {
    width: 50px;
    height: auto;
    border-radius: 50%;
}

.ticket__dialog--content {
    overflow-y: auto;
}

.ticket__dialogs--item {
    padding: 0.7rem 1.4rem;
    transition: 0.2s linear;
    cursor: pointer;
}

.ticket__dialogs--item:hover,
.ticket__dialogs--item.active {
    background: #214780;
}

.ticket__dialogs--img {
    width: 60px;
    height: auto;
    margin-right: 1.2rem;
    border-radius: 50%;
}

.ticket__dialogs--index {
    margin-left: 1.2rem;
    background: #61d64a;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 1.4rem;
    color: #fff;
    font-weight: 500;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket__dialogs--title {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
}

.ticket__dialogs--text {
    margin-top: 0.6rem;
    color: #fff;
}

.anews__button {
    margin-top: 1rem;
    padding: 1rem 2rem;
}

/* Уведомления */
.notify__tab--inner {
    width: 100%;
    display: flex;
    align-items: center;
}

.default__button.disable {
    background: #bababa;
    box-shadow: none;
}

.notify__tab--inner .default__button {
    margin-right: 2rem;
    padding: 1rem 3rem;
}

.notify__tab--inner .default__button:last-child {
    margin-right: 0;
}

.notify__item {
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
}

.notify__item:first-child {
    margin-top: 0;
}

.notify__item--img--inner {
    width: 80px;
    display: flex;
    margin-right: 2rem;
    position: relative;
}

/* .notify__item--img--inner:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #61d64a;
    position: absolute;
    bottom: 4px;
    right: 6px;
} */

.point_notify {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #61d64a;
    position: absolute;
    bottom: 4px;
    right: 6px;
}

.notify__name--inner {
    color: #222;
    font-weight: 700;
}

.notify__box {
    padding: 0.7rem 2rem;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    /* margin-top: 2rem; */
}

.notify__title {
    color: #527dbe;
    font-weight: 700;
    font-size: 1.9rem;
}

.notify__text {
    margin-top: 1.3rem;
    color: #344f7a;
}

.notify__type {
    background: #d8d8d8;
    padding: 0.6rem 2.5rem;
    color: #fff;
    border-radius: 10px;
}

.notify__wrapper {
    margin-top: 3rem;
}

.notify__check {
    color: #acafbe;
    margin-right: 1rem;
}

.notify__check--inner {
    display: flex;
    align-items: center;
}

.notify__check--img {
    width: 30px;
    border-radius: 50px;
    border: 2px solid #fff;
    margin-left: -0.5rem;
}

.notify__icon {
    cursor: pointer;
    margin-left: 1rem;
}

.mall5 {
    margin: 0.5rem;
}

td,
th {
    font-weight: 400;
    text-align: left;
}

.last__item th,
.last__item td {
    padding: 0.8rem 0.5rem;
    border-bottom: 1px solid #dfdfdf;
}

.last__item th {
    color: #174073;
    font-weight: 500;
}

.last__item:last-child th,
.last__item:last-child td {
    border-bottom: none;
}

/* Перевод средств */
.trans__inner {
    display: flex;
    align-items: flex-start;
}

.trans__side {
    max-width: 260px;
}

.trans__content {
    margin: 0rem 2rem;
}

.trans__side--title {
    color: #222;
    font-weight: 500;
    font-size: 1.8rem;
}

.trans__side--box {
    background: #f0f0f0;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1.5rem 0;
}

.trans__side--item {
    padding: 1rem 2rem;
    transition: 0.2s linear;
    cursor: pointer;
}

.trans__side--item:hover,
.trans__side--item.active {
    background: #d2e4ff;
}

.trans__side--img {
    width: 40px;
    margin-right: 0.8rem;
}

.trans__side--item--title {
    font-size: 1.8rem;
    font-weight: 500;
}

.trans__side--item--text {
    margin-top: 0.4rem;
    color: #acafbe;
    font-size: 1.6rem;
}

.trans__warn {
    position: relative;
    background: #fff7d0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2rem;
}

.trans__warn--bg {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none;
    max-height: 100%;
}

.trans__warn--text--inner {
    max-width: 380px;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.trans__warn--text--title {
    font-size: 2rem;
    color: #222;
    font-weight: 500;
}

.posr {
    position: relative;
}

.trans__warn--text--text {
    margin-top: 1rem;
    color: #767676;
}

.transaction__content {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 3rem;
    position: relative;
}

.trans__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
}

.trans__label {
    color: #acafbe;
    cursor: pointer;
    padding-left: 3rem;
}

.trans__label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: #f7f7f7;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    position: absolute;
    top: -2px;
    left: 0;
}

.trans__label:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: #61d64a;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 4px;
    transition: 0.2s linear;
    opacity: 0;
}

.checkbox {
    display: none;
}

.checkbox:checked + label:after {
    opacity: 1;
}

.trans__info--val {
    font-weight: 500;
}

.trans__info--comm {
    margin-top: 0.5rem;
}

.green {
    color: #61d64a;
}

.trans__item {
    width: 48%;
    height: 110px;
}

.trans__select,
.trans__input {
    color: #a2a2a2;
    padding: 1.1rem 1.5rem;
    font-size: 1.7rem;
}

.trans__input {
    cursor: text;
}

.trans__input--inner {
    align-items: flex-end;
}

.trans__button {
    font-weight: 500;
    padding: 1.3rem 2rem;
    margin-top: 0;
}

/* Настройки */
.settings__content {
    background: linear-gradient(105.34deg, #161616 0%, #353535 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 3rem;
}

.setting__side--title {
    color: #fff;
    font-weight: 500;
}

.centerflex {
    display: flex;
    justify-content: center;
}

.travel__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 20px;
}

.travel__item {
    background: #fff7d0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2rem 0;
    position: relative;
}

.travel__item--img {
    max-width: 100%;
}

.travel__price {
    position: absolute;
    top: 30px;
    right: 0;
    background: #61d64a;
    font-weight: 500;
    color: #fff;
    padding: 0.8rem 1.6rem;
    border-radius: 10px 0 0 10px;
}

.travel__item--title {
    padding: 1.5rem 2rem;
    text-align: center;
    color: #fff;
    background: linear-gradient(88.71deg, #ff8837 21.92%, #f8bb4a 79.99%);
    font-weight: 500;
    font-size: 1.8rem;
}

.travel__item--text--title {
    text-align: center;
    font-weight: 700;
    margin-top: 1.5rem;
}

.travel__item--text {
    text-align: center;
    margin-top: 0.5rem;
}

.travel__item--button {
    margin-top: 1.5rem;
    width: 80%;
    display: flex;
    justify-content: center;
    background: #5e89cc;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
    padding: 1.3rem 2rem;
}

/* Адаптив */
@media (max-width: 1670px) {
    .trans__item {
        width: 100%;
    }
}

@media (max-width: 1540px) {
    .profile__content--item {
        flex-wrap: wrap;
    }

    .profile__text--wrapper {
        width: 46%;
    }

    .profile__ref--button,
    .profile__ref--input {
        margin: 1.5rem 0rem 0rem;
    }

    .ticket__dialogs {
        max-width: 280px;
    }

    .trans__side {
        display: none;
    }

    .trans__content {
        margin: 0;
    }
}

@media (max-width: 1340px) {
    .admin__sidebar {
        padding: 1.5rem;
        max-width: 280px;
    }

    .admin__header--logo--inner {
        margin-right: 15rem;
    }

    .admin__header--settings {
        margin-right: 5rem;
    }

    .ticket__wrapper {
        padding: 1.5rem;
    }

    .ticket__dialogs {
        max-width: 100%;
        margin: 0rem 0rem 2rem 0;
    }

    .ticket__content {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 978px;
    }

    .news__box--text--inner {
        max-width: 550px;
    }

    .report__box--text--inner {
        max-width: 590px;
    }

    .main__video--inner {
        width: 420px;
    }

    .last__content {
        max-width: 500px;
    }

    .admin__header--logo--inner {
        margin-right: 5rem;
    }

    .admin__control {
        width: 35px;
        height: 35px;
    }

    .admin__header--settings {
        margin-right: 3rem;
    }

    .profile__text--wrapper {
        width: 100%;
        margin: 2rem 0 0 0;
    }

    .pay__title {
        font-size: 2rem;
    }

    .pay__content {
        padding: 1rem;
    }

    .last__item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .last__item th,
    .last__item td {
        width: 100%;
        border-bottom: none;
        text-align: center;
    }

    .last__item th:nth-child(6n),
    .last__item td:nth-child(6n) {
        border-bottom: 1px solid #d7d7d7;
    }
}

@media (max-width: 1100px) {
    .admin__content {
        flex-direction: column;
        align-items: center;
    }

    .admin__content--right {
        margin: 4rem 0 0 0;
    }
}

@media (max-width: 998px) {
    .container {
        max-width: 748px;
    }

    .medium__text {
        font-size: 1.8rem;
    }

    .news__box--text--inner,
    .report__box--text--inner {
        max-width: 100%;
    }

    .news__box,
    .social__box {
        padding: 14rem 4rem 4rem 4rem;
        margin-top: 12rem;
    }

    .rep__box {
        padding: 10rem 4rem 4rem 4rem;
        margin-top: 6rem;
    }

    .build__box {
        padding: 15rem 4rem 4rem 4rem;
        margin-top: 16rem;
    }

    .contact__box {
        padding: 10rem 4rem 4rem 4rem;
        margin-top: 12rem;
    }

    .news__box--img,
    .smi__box--img {
        transform: translateY(50%);
        top: auto;
        bottom: 100%;
        width: 380px;
    }

    .start__item:before {
        display: none;
    }

    .time__inner {
        flex-direction: column-reverse;
        align-items: center;
    }

    .time__wrapper {
        margin-right: 0;
    }

    .time__wrapper:first-child {
        margin-top: 4rem;
    }

    .certificate__inner {
        flex-direction: column;
        align-items: center;
    }

    .certificate__img {
        margin-top: 3rem;
    }

    .mark__content {
        grid-gap: 50px;
    }

    .main {
        padding: 4rem 0;
    }

    .main__text--inner {
        max-width: 370px;
    }

    .main__video--inner {
        width: 315px;
    }

    .arg__item {
        margin-top: 3rem;
    }

    .arg {
        padding: 0rem 0rem 3rem 0;
    }

    .arg__img {
        position: relative;
        transform: translateY(0);
        top: 0;
        right: 0;
        max-width: 590px;
        width: 100%;
    }

    .arg__inner {
        margin-top: 3rem;
    }

    .last__inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .last__content,
    .last__review {
        max-width: 100%;
    }

    .last__review {
        margin-top: 3rem;
    }

    .admin__header--settings {
        display: none;
    }

    .admin__sidebar {
        padding: 2px 8px;
        max-width: 220px;
    }

    .admin__nav--icon {
        margin-right: 1rem;
    }

    .admin__nav--link.big {
        font-size: 1.6rem;
    }

    .admin__wallet {
        grid-gap: 15px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 460px;
    }

    .title {
        font-size: 3rem;
    }

    .section__padding {
        padding: 3rem 0;
    }

    .auth__input--inner {
        width: 100%;
    }

    .auth__input--inner:nth-child(2) {
        margin-top: 3.5rem;
    }

    .default__button {
        padding: 1.3rem 4rem;
        font-size: 1.8rem;
    }

    .auth__button {
        margin-top: 4rem;
    }

    .auth__content {
        margin-top: 3rem;
    }

    .auth__label {
        font-size: 1.8rem;
    }

    .news__box--text--title {
        font-size: 2.4rem;
    }

    .news__box--text--text {
        margin-top: 1.5rem;
    }

    .news__item {
        flex-direction: column;
        align-items: center;
    }

    .news__item--img {
        width: 100%;
    }

    .news__item--text--inner {
        border-radius: 0rem 0rem 10px 10px;
    }

    .news__button {
        margin-top: 2rem;
    }

    .report__box--item {
        flex-direction: column;
        align-items: center;
    }

    .report__box--date,
    .report__box--type,
    .report__box--operate,
    .report__box--price,
    .report__box--val,
    .report__box--total {
        width: 100%;
        margin-top: 1rem;
    }

    .report__box--date {
        margin-top: 0;
    }

    .report__box--item p {
        text-align: center;
    }

    .report__date {
        font-size: 2.2rem;
    }

    .rep__box {
        margin-top: 8rem;
    }

    .rep__box--text--inner {
        margin-top: 2rem;
    }

    .time__wrapper {
        width: 100%;
    }

    .time__title {
        font-size: 2.4rem;
    }

    .program__content {
        flex-wrap: wrap;
        justify-content: center;
    }

    .program__item {
        width: 100%;
        margin: 1rem;
    }

    .review__title {
        margin-top: 4rem;
    }

    .vector__box--item {
        margin: 1rem;
    }

    .some {
        padding: 3rem 0;
    }

    .start__content {
        margin-top: 3rem;
    }

    .certificate__text--title {
        font-size: 2.2rem;
    }

    .certificate__text--text {
        margin-top: 1.5rem;
    }

    .mark__tab {
        font-size: 2rem;
        padding: 1rem 2rem;
        margin-right: 2rem;
    }

    .mark__info--inner {
        flex-direction: column;
        align-items: center;
    }

    .mark__info {
        max-width: 100%;
    }

    .mark__info--img {
        margin-top: 3rem;
    }

    .mains__inner {
        flex-direction: column-reverse;
        align-items: center;
    }

    .main__text--inner {
        margin-top: 3rem;
        max-width: 100%;
    }

    .main__video--img {
        top: 0;
        right: 0;
    }

    .main__video--inner {
        border: none;
        width: 100%;
    }

    .mains__button {
        margin-top: 3rem;
    }

    .stats__inner {
        flex-direction: column;
        align-items: center;
    }

    .stats__item {
        margin-top: 2rem;
        width: 100%;
    }

    .stats__item:first-child {
        margin-top: 0rem;
    }

    .company {
        padding: 6rem 0rem 10rem 0;
    }

    .company__content {
        top: -50px;
    }

    .arg__item--icon {
        margin-right: 1.5rem;
    }

    .arg__item--title {
        font-size: 2rem;
    }

    .arg__item--text {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .mnews__content {
        margin-top: 2rem;
    }

    .last__wrapper {
        margin-top: 2rem;
    }

    .last__title {
        font-size: 2.4rem;
    }

    .last__inner {
        margin-top: 4rem;
    }

    .admin__nav--wrapper,
    .copy {
        display: none;
    }

    .admin__sidebar {
        width: auto;
    }

    .admin__nav--icon {
        margin-right: 0;
    }

    .admin__header--inner {
        flex-direction: column-reverse;
    }

    .admin__header--wrapper {
        width: 100%;
        justify-content: space-between;
    }

    .admin__header--wrapper:last-child {
        justify-content: flex-end;
    }

    .admin__header {
        padding: 1rem 2rem;
    }

    .admin__header--logo--inner {
        margin-right: 0;
    }

    .admin__content {
        width: calc(100% - 44px);
    }

    .admin__last--program,
    .admin__last--sum {
        width: 35%;
    }

    .admin__last--date {
        width: 30%;
    }

    .draw__title {
        font-size: 2rem;
        margin-top: 2rem;
    }

    .draw__text,
    .draw__content {
        margin-top: 2rem;
    }

    .pay__wrapper {
        flex-direction: column;
        /* align-items: flex-start; */
    }

    .pay__button {
        margin-top: 1rem;
    }

    .pay__input {
        margin-right: 0;
    }

    .operation__select,
    .operation__filter .flex,
    .operation__date {
        width: 100%;
    }
}

@media (max-width: 570px) {
    .notify__item {
        flex-direction: column;
        align-items: flex-start;
    }

    .notify__item--img--inner {
        margin: 0rem 0rem 2rem 0;
    }
}

@media (max-width: 500px) {
    .profile__content--info--inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile__content--info--img {
        margin: 0rem 0rem 2rem 0;
    }

    .profile__info--value {
        font-size: 3rem;
    }

    .profile__ref--button {
        padding: 1.5rem 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .admin__content {
        padding: 1rem;
    }

    .profile__content {
        padding: 1.5rem;
    }

    .content__margin {
        margin-top: 1.5rem;
        justify-content: center;
        /* align-items: baseline; */
        align-items: center;
        flex-direction: column;
    }

    .default__margin,
    .admin__content--right {
        margin-top: 2.5rem;
    }

    .profile__info--text {
        width: 100%;
    }

    .admin__content--right {
        max-width: 100%;
    }

    .admin__title--inner {
        font-size: 1.8rem;
    }

    .presents__content {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    .draw__button {
        margin-top: 2rem;
    }

    .mall {
        margin: 1rem 0;
    }

    .mall:first-child {
        margin: 0rem 0rem 1rem 0;
    }

    .operation__text {
        font-size: 1.6rem;
    }

    .ticket__ava--inner {
        /* margin-right: 1rem; */
    }

    .ticket__ava {
        width: 40px;
    }

    .ticket__message {
        padding: 0.5rem;
    }

    .ticket__input,
    .ticket__message,
    .ticket__send--button {
        font-size: 1.2rem;
    }

    .ticket__send--button {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .transaction__content,
    .settings__content {
        padding: 1.5rem;
    }

    .draw__button {
        margin-top: 0;
    }
}

@media (max-width: 480px) {
    .flex {
        align-items: center;
    }

    .container {
        max-width: 300px;
    }

    .admin__wallet {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .title {
        font-size: 2.3rem;
    }

    .medium__text {
        font-size: 1.6rem;
    }

    .text__name {
        font-size: 2rem;
    }

    .auth__input,
    .auth__label {
        font-size: 1.6rem;
    }

    .news__box--img,
    .smi__box--img {
        width: 280px;
    }

    .news__box,
    .social__box,
    .rep__box {
        padding: 10rem 2rem 2rem 2rem;
        margin-top: 10rem;
    }

    .news__item--text--inner {
        padding: 1.5rem;
    }

    .news__box--text--title {
        font-size: 2rem;
    }

    .report__date {
        font-size: 2rem;
    }

    .report__content,
    .report__item {
        margin-top: 2rem;
    }

    .report__box {
        margin-top: 1.5rem;
    }

    .rep__box {
        padding: 8rem 2rem 2rem 2rem;
        margin-top: 5rem;
    }

    .time__img--inner {
        width: 100%;
        margin-left: 0;
    }

    .time__img--preview {
        left: 0;
        top: 0;
    }

    .program__content {
        margin-top: 3rem;
    }

    .program {
        padding: 3rem 0rem 7rem 0;
    }

    .review__content {
        margin-top: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .review__item {
        flex-direction: column;
        align-items: center;
    }

    .review__item--img {
        margin: 0rem 0rem 1rem 0;
    }

    .mark__tab--inner {
        flex-direction: column;
        align-items: center;
    }

    .mark__content {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .mark {
        padding: 3rem 0;
        margin-top: 2rem;
    }

    .mark__tab {
        margin: 0rem 0rem 1rem 0;
    }

    .mark__tab:last-child {
        margin: 0;
    }

    .mark__info--inner {
        margin-top: 2rem;
    }

    .mark__info--title {
        font-size: 2.5rem;
    }

    .mark__info--img {
        width: 170px;
    }

    .mark__item--wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .mark__item--wrapper--text {
        max-width: 100%;
        margin-top: 1rem;
    }

    .mark__item--content {
        margin-top: 2rem;
    }

    .line:before,
    .line:after {
        display: none;
    }

    .mark__item--box {
        flex-direction: column;
        align-items: center;
    }

    .mark__item--box--item .mark__item--box--item_two{
        width: 100%;
        margin-top: 2rem;
    }

    .mark__item--box--item:first-child {
        margin-top: 0;
    }

    .mark__item--box--item:before {
        display: none;
    }
    .mark__item--box--item_two:before {
        display: none;
    }

    .mark__item--box--text--inner {
        align-items: center;
    }

    .mark__item--button {
        width: 80%;
    }

    .main__dots2 {
        display: none;
    }

    .stats__item--val {
        font-size: 4rem;
    }

    .stats__item--text {
        font-size: 1.8rem;
    }

    .company__margin {
        margin-top: 3rem;
    }

    .company {
        padding: 3rem 0rem 10rem 0;
    }

    .company__title {
        font-size: 2.4rem;
    }

    .last__review--item {
        flex-direction: column;
        align-items: center;
    }

    .review__item--box {
        margin-top: 1rem;
    }

    .admin__header--logo--inner {
        height: 60px;
        width: 60px;
        /* display: none; */
    }

    .admin__header--wrapper {
        /* margin-top: 1rem; */
        display: flex;
        /* justify-content: space-between; */
        justify-content: space-around;
        align-items: center;
    }

    .admin__header--wrapper:last-child {
        margin-top: 0;
    }

    .admin__balance,
    .admin__news--text--text {
        font-size: 1.3rem;
        margin-left: 10px;
    }

    .admin__news--text--title {
        font-size: 1.6rem;
    }

    .admin__wallet--text--inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin__wallet--text--inner .flexend {
        align-items: flex-start;
        margin: 1rem 0 0 0;
    }

    .admin__last--item {
        flex-direction: column;
        align-items: center;
    }

    .admin__last--text {
        width: 100%;
        text-align: center;
        padding: 0;
        margin-top: 0.8rem;
        flex-direction: column;
        align-items: center;
    }

    .admin__last--program {
        margin-top: 0;
    }

    .draw__title {
        font-size: 1.6rem;
    }

    .draw__text {
        font-size: 1.4rem;
    }

    .pay__input {
        width: 88%;
    }

    .output__cansel {
        margin-top: 1rem;
    }
}
