.deposit__main--right-footer {
    max-width  : 1080px;
    display    : flex;
    justify-content: center;
    align-items: center;
}

.deposit__main--right-footer-block {
    // width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : start;
}

.deposit__main--right-footer-block-container {
    display       : flex;
    flex-direction: row;
}

.deposit__main--right-footer-block-item {
    display       : flex;
    flex-direction: column;
    align-items   : baseline;
    max-width     : 300px;
    margin        : 15px;
    text-align    : flex-start;
}

.deposit__main--right-footer-block-item-unit {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.deposit__main--right-footer-block-item-icon {
    width : 25px;
    height: 25px;
    margin: 10px;
    color : #A3ABB7;
}

.deposit__main--right-footer-block-item-text {
    font-weight: 400;
    font-size  : 14px;
    line-height: 14px;
    color      : #000000;
}

.deposit__main--right-footer-block-item-text-purple {
    font-weight: 400;
    font-size  : 14px;
    line-height: 14px;
    margin     : 10px;
    color      : #13599F;
}

.deposit__main--right-footer-block-item-text-green {
    font-weight: 400;
    font-size  : 14px;
    line-height: 14px;
    margin     : 10px;
    color      : #61D64A;
}

.deposit__main--right-footer-block-item-text-red {
    font-weight: 400;
    font-size  : 14px;
    line-height: 14px;
    margin     : 10px;
    color      : #FF3158;
}

@media(max-width: 768px) {
    .deposit__main--right-footer-block-item {
        // margin: 10px;
    }

    .deposit__main--right-footer-block-item-text {
        font-size  : 12px;
        line-height: 12px;
    }

    .deposit__main--right-footer-block-item-icon {
        width : 18px;
        height: 18px;
    }
}