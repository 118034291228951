.App {
    min-height: 100vh;
    color     : white;
}

.pagination {
    display        : flex;
    align-items    : center;
    justify-content: center;
    gap            : 1rem;
    margin         : 1rem 0;
    width          : 95%;
}

.pagination .text {
    // opacity  : 0.6;
    font-size: 1.5rem;
}

.page {
    color           : rgb(0, 0, 0);
    height          : 3rem;
    min-width           : 2.5rem;
    display         : flex;
    align-items     : center;
    justify-content : center;
    font-weight     : bold;
    border          : solid 0.2rem #4aa5be;
    background-color: transparent;
    border-radius   : 0.7rem;
    cursor          : pointer;
    padding: 0px 4px;
}

.page.active,
.page:hover {
    background-color: #61D64A;
}

.page.disabled {
    background-color: transparent !important;
    cursor          : not-allowed;
    opacity         : 0.5;
}

h2 {
    margin-top    : 4rem;
    text-align    : center;
    text-transform: uppercase;
}

.App img {
    margin : 0 auto;
    height : 10rem;
    display: block;
}

.App .title {
    text-align      : center;
    padding         : 1rem;
    border-radius   : 0.7rem;
    color           : #61dafb;
    background-color: #61dafb0d;
    width           : fit-content;
    margin          : 0 auto;
    font-family     : monospace;
}

.items {
    margin    : 0 auto;
    margin-top: 4rem;
    width     : clamp(35rem, 60vw, 50rem);
}

.App .item {
    font-size       : 1.6rem;
    display         : flex;
    gap             : 2rem;
    align-items     : center;
    background-color: #222222;
    border-radius   : 1rem;
    padding         : 2rem;
    margin-bottom   : 4rem;
}

.App .item p {
    margin-bottom: 0.4rem;
}

.App .item img {
    margin          : 0;
    width           : 8rem;
    height          : 8rem;
    border-radius   : 50%;
    background-color: #121212;
}

.App .item .name {
    text-transform: capitalize;
}

.App .item .username {
    opacity  : 0.7;
    font-size: 1.4rem;
}

.App .item .job {
    font-weight   : 600;
    text-transform: uppercase;
    font-size     : 1.3rem;
}

.App .item .status {
    font-size     : 1.2rem;
    font-weight   : bold;
    text-transform: uppercase;
    margin-top    : 0.75rem;
    padding       : 0.5rem 0.7rem;
    border-radius : 0.4rem;
    width         : fit-content;
}

.App .item .status.success {
    background-color: rgba(67, 255, 76, 0.1);
    color           : #c4ffc4;
}