.svg_container {
  // height: 1vh;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  // width: 100vw;
  // padding-bottom: 100%;
  // vertical-align: top;
  // overflow      : hidden;
}

.svg-chart-container {
  // height: 95vh !important;
  position: relative; //
  width: 100%;
  height: 100%;
}

.svg-content {
  // display: inline-block;
  // position: absolute;
  // top: 0;
  // left: 0;
  // position: relative; //
}

.button_str_arrow {
  // @media (max-width: 460px) {
  //   margin-top: 20px !important;
  // }
}

.chart {
  position: relative; //
}
.center-group {
  position: relative; //
}
.nodes-wrapper {
  position: relative; //
}
.nodes {
  position: relative; //
}

// .chart-wrapper .line {
//   fill: black;
//   stroke: steelblue;
//   stroke-width: 5px;
//   border-color:black;
// }

// .chart-wrapper .focus.line {
//   stroke: steelblue;
//   stroke-dasharray: 2, 5;
//   stroke-width: 2;
//   opacity: 0.5;
//   border-color:black;
// }
// .node-foreign-object-div{
//   position: relative; //
// }
// for https://codepen.io/ahmadnasr/pen/BaNQweQ
:root {
  --color-first: #65587f;
  --color-second: #f18867;
  --color-third: #e85f99;
  --color-forth: #50bda1;
  --block-width: 200px;
  --block-height: 270px;
  --border-width: 0.625rem;
  --border-radius-outer: 8px;
  --border-radius-inner: calc(var(--border-radius-outer) / 2);
  // --font-plain           : 'IBM Plex Sans', sans-serif;
  // --font-special         : 'Fredoka One', sans-serif;

  // box-sizing: border-box;
  // line-height: 1.4
}

// *, *:before, *:after { box-sizing: inherit }

.bodydiv {
  // -webkit-position: relative; //
  // position: relative; //
  // -webkit-transform: scale(1.5);//
  // transform: scale(1.5);//
  -webkit-transform-origin: left top; //
  transform-origin: left top; //
}

.rainbow {
  width: 100%;
  height: 100%;
  animation: o-rotate-360 linear 8s infinite;

  span {
    display: block;
    width: 100%;
    height: 100%;
    // position: relative;
    // -webkit-position: relative; //
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-position: absolute; //
      left: 100%;
      top: 0;
    }

    &:first-child {
      background: var(--color-first);
      &:after {
        background: var(--color-second);
      }
    }

    &:last-child {
      background: var(--color-third);
      &:after {
        background: var(--color-forth);
      }
    }
  }
}

// Components

.c-subscribe-box {
  // width: var(--block-width);
  // height: var(--block-height);
  width: 100%;
  overflow: hidden;
  // position: fixed;
  // -webkit-position: relative; //
  box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
  border-radius: var(--border-radius-outer);

  &__wrapper {
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    // -webkit-position: absolute; //
    // position: absolute;
    top: 50%;
    left: 50%;
    // -webkit-transform: -webkit-translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    background: #f7fafc;
    // padding: 1.2rem 1rem 1.8rem;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-inner);
  }

  &__title {
    font-size: 1.6rem;
  }

  &__desc {
    font-size: 0.935rem;
    margin: 0.7rem auto 1.8rem;
    max-width: 240px;
  }

  &__form {
    margin-top: auto;
  }
}

.c-form {
  &--accent {
    input {
      &:hover,
      &:active,
      &:focus {
        border-color: var(--color-third); // accent color
        box-shadow: 0 0 0 3px hsla(335, 75%, 64%, 0.25);
      }
    }

    [type="submit"] {
      background: var(--color-third);
      border-color: var(--color-third);
      color: #fff;
    }
  }
}

// Objects

@keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Elements

[type="submit"] {
  margin-bottom: 0;
  font-family: var(--font-special);
  font-weight: normal;
  letter-spacing: 0.015em;
  font-size: 1.1rem;

  &:active {
    transform: scale(0.97);
  }
}

input {
  font-family: inherit;
  color: inherit;
  outline: none;
  font-size: 93%;
  transition: all 300ms ease;
}

h3 {
  margin: 0;
  letter-spacing: -0.015em;
  font-family: var(--font-special);
  font-weight: normal;
  line-height: 1.4;
}

// Utilities
.u-align-center {
  text-align: center;
}
// end for https://codepen.io/ahmadnasr/pen/BaNQweQ

.ov-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

// https://codepen.io/Mamboleoo/pen/zYOJOGb
.border-changing {
  // font-size: 24px;
  border: 5px solid black;

  // padding: 2rem 1rem;
  // min-height: 3em;
  // resize: both;
  // background: #ffd73e33;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
    1;
  border-radius: 5px;
}

.border-body {
  // display: flex;
  // height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
