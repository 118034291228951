/* Шапка */
.header{
    padding: 2rem 0;
    position: relative;
    border-bottom: 1px solid #C8DAEA;
}

.header__inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header__img--inner{
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(45vw - 130px);
    background: url("../../../assets/img/header-blue.svg") center no-repeat;
    background-size: cover;
}

.header__top, .header__bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__bottom{
    margin-top: 2.5rem;
}

.header__wrapper{
    display: flex;
    align-items: center;
}

.header__social--inner{
    margin-left: 7rem;
    display: flex;
    align-items: center;
}

.header__social--link{
    display: flex;
    align-items: center;
    color: #668faf;
    font-size: 1.4rem;
    font-weight: 500;
    margin-right: 3rem;
}

.header__social--link:last-child{
    margin-right: 0;
}

.header__lang--inner{
    display: flex;
    padding: 0.5rem;
    border-radius: 50px;
    border: 1px solid #fff;
}

.header__lang--item{
    margin-right: 0.6rem;
    cursor: pointer;
}

.header__lang--item:last-child{
    margin-right: 0;
}

.header__nav, .header__auth{
    display: flex;
    align-items: center;
}

.header__nav--link{
    font-size: 1.5rem;
    color: #90a1ae;
    margin-right: 4.1rem;
    padding-bottom: 0.6rem;
    position: relative;
}

.header__nav--link:before{
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background: #158AFF;
    transition: .2s linear;
    position: absolute;
    bottom: 0;
    left: 0;
}

.header__nav--link:last-child{
    margin-right: 0;
}

.header__nav--link:hover{
    color: #24333F;
}

.header__nav--link:hover:before{
    width: 100%;
}

.header__auth--button{
    padding: 0.7rem 3rem;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 1.5rem;
}

.header__auth--button:last-child{
    margin-right: 0;
}


.header__auth--button:hover{
    border: 1px solid #158AFF;
    filter: drop-shadow(0px 2px 4px #158AFF);
}

.cabinet, .menu{
    cursor: pointer;
    display: none;
}

.header__circle{
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: 0;
    left: 50px;
    max-width: 50%;
}

/* Модальное меню */
.mobile__menu{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    transform: translateX(-110%);
    transition: .4s ease-in-out;
    overflow-y: auto;
}

.mobile__menu.active{
    transform: translateX(0);
    opacity: 1;
}

.close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

@media(max-width: 1200px){
    .header__social--inner, .header__nav{
        flex-wrap: wrap;
    }

    .header__social--link{
        width: 50%;
        margin: 1rem 0 0 0;
    }

    .header__social--link:first-child, .header__social--link:nth-child(2){
        margin-top: 0;
    }

    .header__nav--link{
        margin: 0.5rem 4rem .5rem .5rem;
    }
}
@media(max-width: 998px){
    .header__social--inner{
        margin-left: 4rem;
    }

    .header__auth{
        display: none;
    }

    .header__nav{
        width: 70%;
    }

    .cabinet{
        display: block;
        /* margin-right: 1rem */
    }
}
@media(max-width: 768px){
    .header__social--inner, .header__bottom, .header__nav--link:before{
        display: none;
    }

    .menu{
        display: block;
        margin-left: 1rem
    }

    .header__img--inner{
        width: calc(65vw - 130px);
    }

    .header__nav{
        flex-direction: column;
        align-items: flex-start;
    }

    .header__nav--link{
        padding-bottom: 0;
        margin: 1.5rem 0rem 0rem 0rem;
        font-size: 1.6rem;
        color: #000;
    }

    .header__nav--link:first-child{
        margin-top: 0;
    }

    .header__social--inner.mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .header__social--inner{
        width: 100%;
        margin: 2rem 0 0 0;
    }

    .header__social--link{
        width: 100%;
        font-size: 1.6rem;
        margin-top: 2rem;
    }

    .header__social--link:nth-child(2){
        margin-top: 2rem;
    }
}
@media(max-width: 480px){
    .header__img--inner{
        width: calc(60vw - 10px);
    }
}