.settings__top-section-email {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : flex-end;
    width          : 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items   : flex-start;
    }
}

.settings__medium-section-select-option {
    width: "20px";
}

.settings__bottom-section {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    width          : 100%;

    @media (max-width: 1340px) {
        flex-direction : column-reverse;
        justify-content: center;
        align-items    : center;
    }
}

.settings__bottom-section-login {
    display       : flex;
    flex-direction: column;
    width         : 50%;

    @media (max-width: 1340px) {
        width: 100%;
    }
}

.settings__bottom-section-qr-code {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 50%;

    @media (max-width: 1340px) {
        width: 100%;
    }
}