@font-face {
    font-family: "Montserrat";
    src        : url("../../../assets/fonts/GraphikLCGRegular.ttf");
    font-weight: 400;
    font-style : normal;
}

@font-face {
    font-family: "Montserrat";
    src        : url("../../../assets/fonts/GraphikLCGMedium.ttf");
    font-weight: 500;
    font-style : normal;
}

@font-face {
    font-family: "Montserrat";
    src        : url("../../../assets/fonts/GraphikLCGBold.ttf");
    font-weight: 700;
    font-style : normal;
}


.container {
    display        : flex;
    flex-direction : column;
    min-height     : 650px;
    height         : 100%;
    padding        : 6rem 0rem;
    background     : url("../../../assets/img/plane-in-clouds.png") center no-repeat;
    background-size: cover;
    align-items    : center;
    font-family    : "Montserrat", sans-serif;

    // @media (max-width: 990px) {
    //     background     : url("../../../assets/img/airplane-center2.png") center no-repeat;
    //     background-size: cover;
    // }


    // @media (max-width: 770px) {
    //     background     : url("../../../assets/img/airplane-center.png") center no-repeat;
    //     background-size: cover;
    // }
}

.box_title {
    width          : 80%;
    display        : flex;
    justify-content: flex-start;

    @media (max-width: 1200px) {
        margin-bottom: 30px;
    }

    @media (max-width: 770px) {
        width          : 100%;
        justify-content: center;

    }
}

.title {
    max-width    : 801px;
    font-size    : 70px;
    line-height  : 85px;
    font-weight  : 900;
    width        : 100%;
    color        : #FFF;
    filter       : drop-shadow(0px 4px 20px rgba(0, 25, 253, 0.25));
    border-bottom: 5px solid #FFC700;

    @media (max-width: 770px) {
        max-width  : 80%;
        font-size  : 50px;
        line-height: 65px;
        font-weight: 700;
    }

    @media (max-width: 480px) {
        max-width  : 80%;
        font-size  : 30px;
        line-height: 45px;
        font-weight: 600;
    }
}

.advantages {
    background     : url("../../../assets/img/roadMapLine.png") no-repeat;
    width          : 95%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    min-height     : 400px;
    height         : 100%;
    margin-left    : 50px;

    @media (max-width: 1200px) {
        margin-left: 0px;
        width      : 98%;
        background : none;
    }

    @media (max-width: 480px) {
        justify-content: center;
    }
}

.universal_box {
    position       : relative;
    width          : 175px;
    height         : 190px;
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : flex-start;
    padding        : 10px 15px;
    border-radius  : 15px;
    background     : rgba(255, 255, 255, 0.27);
    filter         : drop-shadow(0px 4px 26px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(7px);
    z-index        : 1;
    transition     : 0.2s ease linear;


    &:hover {
        cursor         : pointer;
        backdrop-filter: blur(20px);
        background     : #207cd8;

        .inner_text {
            color: #FFF;
        }

        .inner_title {
            color: rgb(71, 250, 0);
        }

        .inner_small_text {
            font-weight: 500;
        }

    }

    @media (max-width: 1200px) {
        margin: 20px;
    }

    @media (max-width: 480px) {
        align-items    : center;
        width          : 220px;
        height         : 218px;
        justify-content: space-between;
    }
}

.first_box {
    display      : flex;
    min-height   : 100%;
    align-self   : flex-end;
    margin-bottom: 40px;

    @media (max-width: 1300px) {
        align-self   : center;
        margin-bottom: 0px;
    }

    @media (max-width: 980px) {}

}

.second_box {
    display      : flex;
    min-height   : 100%;
    align-self   : flex-end;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
        align-self   : center;
        margin-bottom: 0px;
    }
}

.third_box {
    display      : flex;
    min-height   : 100%;
    align-self   : center;
    margin-bottom: 50px;


    @media (max-width: 1200px) {
        margin-bottom: 0px;
    }
}

.fourth_box {
    display      : flex;
    min-height   : 100%;
    align-self   : flex-end;
    margin-bottom: 70px;

    @media (max-width: 1200px) {
        align-self   : center;
        margin-bottom: 0px;
    }
}

.fifth_box {
    display   : flex;
    min-height: 100%;
    align-self: flex-end;

    @media (max-width: 1300px) {
        align-self: center;
        margin-top: 150px;
    }

    @media (max-width: 1200px) {
        margin-top: 0px;
    }

}

.sixth_box {
    display   : flex;
    min-height: 100%;
    align-self: flex-start;


    @media (max-width: 1500px) {
        align-self: center;
    }
}

.inner_title {
    font-size  : 55px;
    line-height: 65px;
    font-weight: 700;
    color      : #FFC700;
    filter     : drop-shadow(4px 5px 0px rgba(71, 103, 150, 0.63));

    @media (max-width: 480px) {
        font-size  : 70px;
        line-height: 80px;
    }
}

.inner_text {
    font-size  : 16px;
    line-height: 20px;
    font-weight: 500;
    color      : #3F3F3F;

    @media (max-width: 480px) {
        text-align : center;
        font-size  : 20px;
        line-height: 30px;
    }
}

.inner_small_text {
    margin-bottom: 15px;
    color        : #FFF;
    font-size    : 12px;
    line-height  : 16px;
    font-weight  : 400;
}



.inner_box_first {
    position        : absolute;
    top             : -25px;
    left            : 20px;
    width           : 45px;
    height          : 45px;
    border-radius   : 9px;
    opacity         : 20%;
    background-color: #FFF;

}

.inner_box_second {
    position        : absolute;
    top             : -22px;
    left            : 23px;
    width           : 38px;
    height          : 38px;
    border-radius   : 6px;
    opacity         : 30%;
    background-color: #FFF;

}

.inner_box_third {
    position        : absolute;
    top             : -20px;
    left            : 25px;
    width           : 34px;
    height          : 34px;
    border-radius   : 5px;
    opacity         : 40%;
    background-color: #FFF;

}

.inner_box_green {
    position        : absolute;
    display         : flex;
    align-items     : center;
    justify-content : center;
    top             : -18px;
    left            : 27px;
    width           : 30px;
    height          : 30px;
    border-radius   : 4px;
    background-color: #FFC700;
    filter          : drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
    z-index         : 100;
}

.inner_box_gray {
    position        : absolute;
    display         : flex;
    align-items     : center;
    justify-content : center;
    top             : -18px;
    left            : 27px;
    width           : 30px;
    height          : 30px;
    border-radius   : 4px;
    background-color: #8AA6C1;
    filter          : drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
    z-index         : 100;
}