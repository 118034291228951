.deposit__container {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    font-family   : Graphik;
    font-style    : normal;
    overflow      : hidden;
}



.deposits__header {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    width          : 100%;
    height         : 50px;
    align-items    : center;
    margin-bottom  : 25px;
}

.deposits__header--left {
    display        : flex;
    flex-direction : row;
    justify-content: center;
}

.deposits__header--left--title {
    font-weight: 500;
    font-size  : 26px;
    line-height: 26px;
    color      : #222222;
}

.deposits__header--left--funds-icon {
    width : 20px;
    height: 18px;
    margin: 3px 10px 0 2px;
}

.deposits__header--right {
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;
    width          : 870px;
    height         : 50px;
}

.deposits__header--right-button {
    color  : #949494;
    padding: 10px;


}

.deposits__header--right-button-active {
    color        : #949494;
    padding      : 10px;
    border-bottom: 3px solid #61D64A;
    color        : #ff0000;
}


.deposits__header--item {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.deposits__header--item--active {
    display        : flex;
    flex-direction : row;
    border-bottom  : 3px solid #61D64A;
    justify-content: center;
    align-items    : center;
}

.deposits__header--item--icon--disabled {
    color : #A6A6A6;
    width : 24px;
    height: 24px;
    margin: 3px 10px 0 2px;
}

.deposits__header--item--title-disabled {
    font-weight: 500;
    font-size  : 26px;
    line-height: 26px;
    color      : #949494;
}

.deposits__header--item--icon--active {
    color : #ff0000;
    width : 24px;
    height: 24px;
    margin: 3px 10px 0 2px;
}

.deposits__header--item--title-active {
    font-weight: 500;
    font-size  : 26px;
    line-height: 26px;
    color      : #222222;
}

.deposit__main {
    display       : flex;
    flex-direction: row;
    max-width     : 100%;
}

.deposit__main--left {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.deposit__main--left-matrix {
    width            : 420px;
    // height        : 460px;
    background       : #1C3B6A;
    border-radius    : 10px;
    margin-bottom    : 27px;
    display          : flex;
    flex-direction   : column;
}

.deposit__main--left-search {
    display          : flex;
    flex-direction   : column;
    width            : 420px;
    // height        : 510px;
    background       : #F0F0F0;
    border           : 1px solid #E1E1E1;
    box-sizing       : border-box;
    box-shadow       : 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius    : 10px;
}

.deposit__main--right {
    width         : 100%;
    height        : 130vh;
    margin        : 0 0px 0 20px;
    background    : #F0F0F0;
    border        : 1px solid #E1E1E1;
    box-sizing    : border-box;
    box-shadow    : 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius : 10px;
    display       : flex;
    flex-direction: column;
}

.deposit__main--left-matrix-item {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    width          : 94%;
    height         : 50px;
    margin         : 20px 15px;
    border-radius  : 7px;
    cursor         : pointer;
}

.deposit__main--left-matrix-item-active {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    width          : 94%;
    height         : 50px;
    margin         : 20px 15px;
    background     : #ff0000;
    border-radius  : 7px;
    cursor         : pointer;
}

.deposit__main--left-matrix-item-number {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
}

.deposit__main--left-matrix-item-box {
    width           : 50px;
    height          : 50px;
    background      : #5B87C7;
    border-radius   : 7px;
    // text-align   : center;
    display         : flex;
    justify-content : center;
    align-items     : center
}

.deposit__main--left-matrix-item-box-number {
    font-weight: 900;
    font-size  : 24px;
    line-height: 24px;
    color      : #FFFFFF;
}

.deposit__main--left-matrix-item-number-text {
    margin-left: 20px;
    font-weight: 500;
    font-size  : 24px;
    line-height: 24px;
    color      : #ffffff;
}

.deposit__main--left-matrix-item-box-green {
    width           : 50px;
    height          : 50px;
    border          : none;
    background      : #61D64A;
    border-radius   : 7px;
    // text-align   : center;
    display         : flex;
    justify-content : center;
    align-items     : center;

    :hover {
        background: #337a25;
    }


}

.deposit__main--left-matrix-item-box-green-icon {
    width : 16px;
    height: 16px;
    color : #FFFFFF;
}

.deposit__main--left-search-title {
    font-weight: 500;
    font-size  : 18px;
    line-height: 18px;
    color      : #464648;
    margin     : 20px 0 10px 30px;
}

.deposit__main--left-search-button-group {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    margin         : 25px 0 25px 0;
}

.deposit__main--left-button-title {
    font-weight: 500;
    font-size  : 18px;
    line-height: 18px;
    display    : flex;
    align-items: center;
    text-align : center;
}

.deposit__main--right-top {
    background       : url(../../../assets/img/deposit-bg.svg) 50%/cover no-repeat, url(../../../assets/img/deposit-bg2.svg) 100%/80% no-repeat;
    width            : 100%;
    height           : 80%;
    display          : flex;
    flex-direction   : column;
    // align-items   : center;
    // overflow-x    : auto;
}

.deposit__main--right-top-multi-card {
    display       : flex;
    flex-direction: row;
}

.deposit__main--right-top-privet-card {
    display           : flex;
    flex-direction    : row;
    justify-content   : flex-start;
    // overflow-x     : auto;
    transform         : translate(-120px);
}

@media(max-width: 1670px) {
    .deposits__header {
        flex-direction : column;
        justify-content: start;
        height         : 80px;
    }

    .deposits__header--left {
        align-self: start;
        margin    : 0px 0px 5px 0px;
    }

    .deposits__header--right {
        align-self: start;
        max-width : 870px;
    }
}

@media(max-width: 1540px) {
    .deposit__main {
        flex-direction: column-reverse;
    }

    .deposit__main--left-matrix {
        margin-top: 20px;
    }

    .deposit__main--right {
        margin: 0px;
    }


}

@media(max-width: 1200px) {
    .deposits__header--item--title-disabled {
        font-size: 18px;
    }

    .deposits__header--item--title-active {
        font-size: 18px;
    }

    .deposit__main--right-footer-block {
        flex-direction: column;
    }
}

@media(max-width: 1100px) {
    .deposits__header--right {
        justify-content: space-between;
        max-width      : 470px;
    }

    .deposit__main--right-center {
        justify-content: end;
    }

}

@media(max-width: 998px) {
    .deposit__main--right {
        height: 130vh;
    }
}

@media(max-width: 768px) {
    .deposit__main--right {
        height: 140vh;
    }
}

@media(max-width: 570px) {
    .deposit__main--left-matrix {
        width: 100%;
    }

    .deposit__main--left-search {
        width: 100%;
    }

    .deposits__header--right {
        justify-content: space-between;
        max-width      : 310px;
    }

    .deposits__header {
        margin-bottom: 40px;
    }

    .deposit__main--right {
        height: 145vh;
    }
}

@media(max-width: 480px) {}